:root {
  --color-green: #119963;
  --color-yellow: #fed957;
  --color-dark-green: #236649;
  --color-pale-yellow: #fffee2;
  --color-gray: #909090;
  --color-white: #ffffff;
  --color-red: #f44822;
}

@font-face {
  font-family: "GenSenRounded-R";
  src: url(./fonts/GenSenRounded-R.ttc);
}
@font-face {
  font-family: "GenSenRounded-B";
  src: url(./fonts/GenSenRounded-B.ttc);
}
@font-face {
  font-family: "GenSenRounded-H";
  src: url(./fonts/GenSenRounded-H.ttc);
}
@font-face {
  font-family: "GenSenRounded-EL";
  src: url(./fonts/GenSenRounded-EL.ttc);
}
@font-face {
  font-family: "GenSenRounded-L";
  src: url(./fonts/GenSenRounded-L.ttc);
}
@font-face {
  font-family: "GenSenRounded-M";
  src: url(./fonts/GenSenRounded-M.ttc);
}
* {
  font-family: "GenSenRounded-R", sans-serif;
  /* overflow: hidden; */
}
.mobile {
  display: block;
}
.ipad {
  display: none;
}
@media only screen and (min-device-width: 768px) {
  .mobile {
    display: none;
  }
  .ipad {
    display: block;
  }
  .logo-ipad {
    max-width: 435px;
  }
  .login-title {
    margin-bottom: 50px !important;
  }
  .third-party-login-buttons-wrapper {
    justify-content: center !important;
  }
  .custom-button {
    font-size: 32px !important;
  }
  .enter-email-title {
    font-size: 40px !important;
  }
  .register-form {
    justify-content: space-between !important;
    min-height: 80vh !important;
    margin-bottom: 140px !important;
  }
  .enter-email-page {
    height: 100%;
    min-height: 100vh;
  }
  .box {
    min-height: 50vh !important;
    min-width: 40vh !important;
    padding: 50px !important;
    justify-content: space-around !important;
  }
  .popup-title {
    font-size: 40px !important;
  }
  .header {
    font-size: 40px !important;
  }
}

.list {
  display: flex;
  align-items: center;
  list-style: none;
}

.listItem {
  margin-right: 20px;
  font-weight: 500;
  cursor: pointer;
}

.login-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}
.golfLogo {
  max-width: 250px;
  height: auto;
  width: auto; /* ie8 */
}
.wrapper {
  width: 75%;
  height: 75%;
  /* -webkit-box-shadow: 0px 5px 33px -21px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px 5px 33px -21px rgba(66, 68, 90, 1);
  box-shadow: 0px 5px 33px -21px rgba(66, 68, 90, 1); */
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
}

.third-party-login-buttons-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  margin: 15px 0;
}

.third-party-login-button {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
  background-color: transparent;
  border: none;
  width: 80px;
}

.google {
  /* background-color: #df4930; */
}
.facebook {
  /* background-color: #507cc0; */
}
.github {
  /* background-color: black; */
}
.linecss {
  /* background-color: #06c755; */
}

.icon {
  width: 60px;
  /* margin-right: 10px; */
}
.date-picker {
  border: none;
}

.react-date-picker__wrapper {
  border: none !important;
  font-family: "GenSenRounded-R", sans-serif;
  overflow: visible !important;
}
.react-date-picker__inputGroup__input {
  font-family: "GenSenRounded-R", sans-serif;
  /* overflow: visible !important; */
}
.inputfields-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
}
.input-wrapper-parent {
  margin-top: 10px;
  width: 100%;
}
.input-wrapper:focus-within {
  border: 2px solid var(--color-green); /* Change to your desired border style */
}
.input-wrapper {
  display: flex;
  flex-direction: row;
  background-color: var(--color-pale-yellow);
  border-radius: 10px;
  margin: 8px 0;
  padding: 5px 0;
  width: 100%;
}
.form-label {
  color: var(--color-dark-green);
  font-size: 18px;
}

.form-label.with-asterisk::after {
  content: "*";
  color: red;
}

.custom-inputfield {
  width: 100%;
  padding: 10px 5px;
  border: none;
  flex: 1;
  background-color: var(--color-pale-yellow);
  font-family: "GenSenRounded-M", sans-serif;
}
.custom-inputfield::placeholder {
  color: var(--color-gray);
  font-size: 18px;
}
.custom-inputfield:focus-visible {
  outline: none;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.image-container img {
  padding-left: 10px;
  height: 30px;
}
.custom-button {
  width: 100%;
  background-color: var(--color-green);
  border-color: var(--color-green);
  border-style: solid;
  border-width: 2px;
  color: white;
  font-size: 18px;
  padding: 12px 20px;
  margin: 3px 0;
  border-radius: 40px;
  font-family: "GenSenRounded-M", sans-serif;
}
.forgot-password-button {
  color: var(--color-gray);
  border: none;
  background-color: transparent;
  font-size: 18px;
  margin-top: 10px;
  font-family: "GenSenRounded-M", sans-serif;
}
.censor-button {
  border: none;
  background-color: transparent;
  padding-right: 20px;
}
.login-title {
  color: var(--color-green);
  margin: 6px 0;
  font-size: 24px;
}
.enter-email-title {
  color: var(--color-dark-green);
  margin-top: 115px;
  margin-bottom: 25px;
}

.link {
  color: inherit;
  text-decoration: none;
}
/* 1536 */
/* @media screen and (max-width: 992px) {
  .wrapper {
    width: 65%;
    height: 90%;
    flex-direction: column;
  }

  .loginTitle {
    display: none;
  }


  .left {
    padding: 20px;
  }

  .right {
    padding: 20px;
  }

  .line {
    height: 0.5px;
    width: 300px;
  }
} */

.header {
  display: flex;
  color: var(--color-green);
  justify-content: center;
  width: 100%;
  margin: 10px 0;
  padding: 7px 0;
  border-bottom: solid 0.5mm #ededed;
}

.auth-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.register-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin-top: 10px;
  justify-content: space-between !important;
  min-height: 75vh;
  margin-bottom: 100px;
}

label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.position-relative {
  width: 100%;
  position: relative;
}

.position-relative.margin-bottom {
  margin-bottom: 20px;
  overflow: visible;
}

.error-message {
  color: red;
  font-size: 14px;
  position: absolute;
  right: 5px;
  font-family: "GenSenRounded-M", sans-serif !important;
}
.enter-email-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.submit-button {
  width: 300px;
  background-color: purple;
  color: white;
  font-weight: bold;
  border: none;
  padding: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
}

/* Popup style */
.popup-title {
  color: var(--color-dark-green);
  font-family: "GenSenRounded-L", sans-serif;
  font-size: 16px;
  margin-top: 73px;
}
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.box {
  /* position: relative;
  width: 70%;
  margin: 0 auto;

  margin-top: 50%; */
  /* padding-bottom: 50px; */
  position: fixed;
  top: 50%;
  left: 50%;
  height: auto;
  min-width: 24vh;
  min-height: 5vh;
  max-height: 70vh;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 15px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.close-icon {
  content: "x";
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}

.enter-new-password-page {
  width: 100%;
  height: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.loading-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.loading-image {
  outline: none;
}
.radio-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
}
.radio-label {
  color: var(--color-dark-green);
  font-size: 18px;
  margin-right: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.radio-label span {
  margin-right: 6px;
}

input:is([type="radio"]) {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background: var(--color-white);
  border: 2px solid var(--color-green);
  border-radius: 0.3em;
  transition: background-color 300ms, border-color 300ms;

  /* &:hover {
    background: pink;
    border: 2px solid pink;
  }

  &:focus {
    outline: 2px solid hotpink;
    outline-offset: 2px;
  } */

  &:checked {
    border: 5px solid var(--color-green);
    background-repeat: no-repeat;
  }
  &:not(:checked) {
    background-repeat: no-repeat;
  }
}

input:is([type="radio"]) {
  border-radius: 50%;
}
.react-date-picker__button svg {
  stroke: var(--color-green);
}
.react-calendar {
  border-radius: 15px;
  padding: 12px 6px;
  font-family: "GenSenRounded-B", sans-serif !important;
  color: var(--color-dark-green);
  width: 331px;
  border: 0.5px solid var(--color-gray) !important;
}

.react-calendar__month-view__days__day--weekend {
  color: var(--color-red) !important;
}
.react-calendar__navigation button {
  color: var(--color-dark-green);
}

.react-calendar button {
  font-family: "GenSenRounded-B", sans-serif;
}
.react-calendar button span {
  font-family: "GenSenRounded-M", sans-serif !important;
}
.react-calendar__navigation__arrow {
  font-size: 30px;
  font-family: "GenSenRounded-L", sans-serif !important;
}
abbr {
  text-decoration: none !important;
}
.react-calendar__tile--now {
  background: var(--color-green) !important;
  color: var(--color-white) !important;
}
.react-calendar__tile {
  color: var(--color-dark-green);
  font-family: "GenSenRounded-M", sans-serif !important;
}
.react-calendar__navigation button:disabled {
  background-color: transparent;
}

.home-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.qr-border {
  border: 20px solid transparent;
  border-image: url(./img/border.png) 30 stretch;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.qr-text {
  display: flex;
  color: var(--color-green);
  justify-content: center;
  width: 100%;
  margin: 10px 0;
  padding: 7px 0;
  font-family: "GenSenRounded-B", sans-serif;
  font-size: large;
}
